
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import GoogleCalendar from './pages/google-calendar'
import EventSync from './pages/event-sync';
import EventbriteCallback from './pages/event-brite-callback';
import CalendlyCallback from './pages/calendly-callback';

function App() {



  return (
    <BrowserRouter>

      <Routes>
        <Route path="/google" element={<GoogleCalendar />} />
        <Route path="/sync" element={<EventSync/>} />
        <Route path="/eventbrite/callback" element={<EventbriteCallback />} />
        <Route path="/calendly/callback" element={<CalendlyCallback />} />
     </Routes>
    </BrowserRouter>
  );
}

export default App;
