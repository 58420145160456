import React, { useState, useEffect } from 'react';
import GoogleCalendar from './google-calendar';
import axios from 'axios';
import { Grid, Card, CardContent, Typography, Button, MenuItem, Select, FormControl, InputLabel, Container, AppBar, Toolbar, Paper, Box, CircularProgress } from '@mui/material';

const App = () => {
  const [googleEvents, setGoogleEvents] = useState([]);
  const [eventbriteOrgs, setEventbriteOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [eventbriteEvents, setEventbriteEvents] = useState([]);
  const [calendlyEvents, setCalendlyEvents] = useState([]);
  const [unsyncedEventbrite, setUnsyncedEventbrite] = useState([]); // Events not synced to Eventbrite
  const [unsyncedCalendly, setUnsyncedCalendly] = useState([]);     // Events not synced to Calendly
  const [loadingStateEventbrite, setLoadingStateEventbrite] = useState({});  // Track loading state for Eventbrite events
  const [loadingStateCalendly, setLoadingStateCalendly] = useState({});      // Track loading state for Calendly events
  const [errorStateEventbrite, setErrorStateEventbrite] = useState({});      // Track error messages for Eventbrite events
  const [errorStateCalendly, setErrorStateCalendly] = useState({});          // Track error messages for Calendly events
  const [showGooglePanel, setShowGooglePanel] = useState(false);

  // Fetch Eventbrite organizations
  const fetchEventbriteOrgs = async () => {
    const accessToken = localStorage.getItem('eventbrite_access_token');
    if (accessToken) {
      try {
        const response = await axios.get('https://www.eventbriteapi.com/v3/users/me/organizations/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setEventbriteOrgs(response.data.organizations);
      } catch (error) {
        console.error('Error fetching Eventbrite organizations:', error);
      }
    }
  };

  const handleEventbriteLogin = () => {
    const client = '5VD3XLRYJ5MZ6IGKH5';
    const eventbriteAuthUrl = `https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=${client}&redirect_uri=${encodeURIComponent(window.location.origin)}/eventbrite/callback`;
    window.location.href = eventbriteAuthUrl;
  };

  const handleCalendlyLogin = () => {
    const client = 'oCcK4x-I8CtrLMigzfdmKQX9JYLI1z9hl2qWAiwoL-o';
    const calendlyAuthUrl = `https://auth.calendly.com/oauth/authorize?client_id=${client}&response_type=code&redirect_uri=${encodeURIComponent(window.location.origin)}/calendly/callback`;
    window.location.href = calendlyAuthUrl;
  };

  // Fetch Eventbrite events for the selected organization
  const fetchEventbriteEvents = async (orgId) => {
    const accessToken = localStorage.getItem('eventbrite_access_token');
    if (accessToken && orgId) {
      try {
        const response = await axios.get(
          `https://www.eventbriteapi.com/v3/organizations/${orgId}/events/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setEventbriteEvents(response.data.events);
      } catch (error) {
        console.error('Error fetching Eventbrite events:', error);
      }
    }
  };

  // Handle Google Calendar events
  const handleGoogleEventsFetched = (fetchedEvents) => {
    setGoogleEvents(fetchedEvents);
  };

  // Handle organization selection
  const handleOrgChange = (e) => {
    const orgId = e.target.value;
    setSelectedOrg(orgId);
    fetchEventbriteEvents(orgId);
  };

  const fetchCalendlyUser = async () => {
    const accessToken = localStorage.getItem('calendly_access_token');
    if (accessToken) {
      try {
        const response = await axios.get('https://api.calendly.com/users/me', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        // Extract the user URI from the response
        return response.data.resource.uri;
      } catch (error) {
        console.error('Error fetching Calendly user:', error);
      }
    }
    return null;
  };

  // Function to fetch Calendly events
  const fetchCalendlyEvents = async () => {
    const accessToken = localStorage.getItem('calendly_access_token');
    if (accessToken) {
      try {
        // First, fetch the current user
        const userUri = await fetchCalendlyUser();

        if (userUri) {
          // Now fetch the events using the user's URI
          const response = await axios.get('https://api.calendly.com/scheduled_events', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              user: userUri, // Provide the user parameter
            },
          });
          setCalendlyEvents(response.data.collection); // This is the array of events
        }
      } catch (error) {
        console.error('Error fetching Calendly events:', error);
      }
    }
    return [];
  };

  const handleShowGoogle = () => {
    setShowGooglePanel(true);
  };

  const handleClearSaved = () => {
    localStorage.removeItem('eventbrite_access_token');
    localStorage.removeItem('calendly_access_token');
  };

  // Compare if the event exists in Eventbrite or Calendly
  const isEventInEventbrite = (googleEvent) => {
    return eventbriteEvents.some(
      (ebEvent) =>
        ebEvent.name.text === googleEvent.summary &&
        new Date(ebEvent.start.utc).getTime() === new Date(googleEvent.start.dateTime).getTime()
    );
  };

  const isEventInCalendly = (googleEvent) => {
    return calendlyEvents.some(
      (calEvent) =>
        calEvent.name === googleEvent.summary &&
        new Date(calEvent.start_time).getTime() === new Date(googleEvent.start.dateTime).getTime()
    );
  };

  // Map Google Event to Eventbrite
  const mapGoogleEventToEventbrite = (googleEvent) => {
    const { summary, description, start, end, hangoutLink } = googleEvent;

    const eventbriteEvent = {
      name: { text: summary, html: summary },
      description: { text: description ? description.replace(/<[^>]*>?/gm, '') : '', html: description || '' },
      start: { utc: new Date(start.dateTime).toISOString() },
      end: { utc: new Date(end.dateTime).toISOString() },
      online_event: !!hangoutLink,
      url: googleEvent.htmlLink,
      currency: 'USD', // Customize as needed
    };
    return eventbriteEvent;
  };

  // Map Google Event to Calendly
  const mapGoogleEventToCalendly = (googleEvent, hostUri) => {
    const { summary, description, start, end, hangoutLink } = googleEvent;
  
    // Prepare the payload for creating a one-off event type
    const calendlyEventType = {
      name: summary, // Event name
      host: hostUri, // The URI of the host (Calendly user)
      duration: Math.round((new Date(end.dateTime).getTime() - new Date(start.dateTime).getTime()) / 60000), // Duration in minutes
      timezone: "America/Los_Angeles", // Set the desired timezone or use the host's timezone
      date_setting: {
        type: 'date_range',
        start_date: start.dateTime.split('T')[0], // Format YYYY-MM-DD for start date
        end_date: end.dateTime.split('T')[0], // Format YYYY-MM-DD for end date
      },
      location: {
        kind: hangoutLink ? 'custom' : 'physical',
        location: hangoutLink ? hangoutLink : 'Main Office',
      },
    };
  
    return calendlyEventType;
  };
  
  

  // Handle adding Google Event to Eventbrite Drafts
  const addEventToEventbriteDrafts = (googleEvent) => {
    const newEvent = mapGoogleEventToEventbrite(googleEvent);
    setEventbriteEvents([newEvent, ...eventbriteEvents]);
    setUnsyncedEventbrite([newEvent, ...unsyncedEventbrite]);
  };

  // Handle adding Google Event to Calendly Drafts
  const addEventToCalendlyDrafts = (googleEvent) => {
    const newEvent = mapGoogleEventToCalendly(googleEvent);
    setCalendlyEvents([newEvent, ...calendlyEvents]);
    setUnsyncedCalendly([newEvent, ...unsyncedCalendly]);
  };

  // Sync Eventbrite Drafts
  const handleSyncEventbriteEvent = async (event) => {
    const accessToken = localStorage.getItem('eventbrite_access_token');
    setLoadingStateEventbrite((prevState) => ({ ...prevState, [event.name.text]: true }));
    setErrorStateEventbrite((prevState) => ({ ...prevState, [event.name.text]: null }));
    if (accessToken && selectedOrg) {
      try {
        await axios.post(
          `https://www.eventbriteapi.com/v3/organizations/${selectedOrg}/events/`,
          { event },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        // Remove from unsynced list and stop loading
        setUnsyncedEventbrite(unsyncedEventbrite.filter((e) => e.name.text !== event.name.text));
        setLoadingStateEventbrite((prevState) => ({ ...prevState, [event.name.text]: false }));
      } catch (error) {
        setErrorStateEventbrite((prevState) => ({ ...prevState, [event.name.text]: error.response.data.error_description || 'Error syncing event' }));
        setLoadingStateEventbrite((prevState) => ({ ...prevState, [event.name.text]: false }));
      }
    }
  };

  const handleSyncCalendlyEvent = async (event) => {
    const accessToken = localStorage.getItem('calendly_access_token');
    setLoadingStateCalendly((prevState) => ({ ...prevState, [event.name]: true }));
    setErrorStateCalendly((prevState) => ({ ...prevState, [event.name]: null }));
  
    if (accessToken) {
      try {
        // First, fetch the current user URI to set as the host
        const hostUri = await fetchCalendlyUser();
  
        if (!hostUri) {
          throw new Error('Unable to retrieve Calendly user URI');
        }
  
        const calendlyEventType = mapGoogleEventToCalendly(event, hostUri);
  
        // Call the Calendly API to create a one-off event type
        await axios.post(
          'https://api.calendly.com/one_off_event_types',
          { ...calendlyEventType },
          { headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' } }
        );
  
        // Remove from unsynced list and stop loading
        setUnsyncedCalendly(unsyncedCalendly.filter((e) => e.name !== event.name));
        setLoadingStateCalendly((prevState) => ({ ...prevState, [event.name]: false }));
      } catch (error) {
        setErrorStateCalendly((prevState) => ({ ...prevState, [event.name]: error.response?.data?.error_description || 'Error syncing event' }));
        setLoadingStateCalendly((prevState) => ({ ...prevState, [event.name]: false }));
      }
    }
  };
  

  useEffect(() => {
    if (localStorage.getItem('eventbrite_access_token')) {
      fetchEventbriteOrgs(); // Fetch Eventbrite organizations once user is authenticated
    }

    if (localStorage.getItem('calendly_access_token')) {
      fetchCalendlyEvents(); // Fetch Calendly events once user is authenticated
    }
  }, []);

  return (
    <Container>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Event Sync Application</Typography>
        </Toolbar>
      </AppBar>

      <Grid container spacing={4} style={{ marginTop: 20 }}>
        {/* Google Events */}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Google Calendar Events</Typography>
              {showGooglePanel ? (
                <GoogleCalendar onEventsFetched={handleGoogleEventsFetched} />
              ) : (
                <Button variant="contained" color="primary" onClick={handleShowGoogle}>
                  Login to Google
                </Button>
              )}
              <Box mt={2}>
                {googleEvents.length > 0 ? (
                  googleEvents.map((event) => {
                    const inEventbrite = isEventInEventbrite(event);
                    const inCalendly = isEventInCalendly(event);
                    return (
                      <Paper elevation={3} style={{ padding: '10px', marginBottom: '10px' }} key={event.id}>
                        <Typography variant="body1">{event.summary}</Typography>
                        <Typography variant="body2">{new Date(event.start.dateTime).toLocaleString()}</Typography>
                        <Box mt={1}>
                          <Typography variant="body2" color={inEventbrite ? 'primary' : 'error'}>
                            {inEventbrite ? 'Exists in Eventbrite' : 'Not in Eventbrite'}
                          </Typography>
                          <Typography variant="body2" color={inCalendly ? 'primary' : 'error'}>
                            {inCalendly ? 'Exists in Calendly' : 'Not in Calendly'}
                          </Typography>
                        </Box>
                        {!inEventbrite && (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => addEventToEventbriteDrafts(event)}
                            style={{ marginTop: 10 }}
                          >
                            Add to Eventbrite
                          </Button>
                        )}
                        {!inCalendly && (
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => addEventToCalendlyDrafts(event)}
                            style={{ marginTop: 10 }}
                          >
                            Add to Calendly
                          </Button>
                        )}
                      </Paper>
                    );
                  })
                ) : (
                  <Typography variant="body2">No events available</Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Eventbrite Events */}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Eventbrite Events</Typography>
              {eventbriteOrgs.length > 0 ? (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Select Organization</InputLabel>
                  <Select value={selectedOrg} onChange={handleOrgChange}>
                    <MenuItem value="">--Select an Organization--</MenuItem>
                    {eventbriteOrgs.map((org) => (
                      <MenuItem key={org.id} value={org.id}>
                        {org.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Button variant="contained" color="primary" onClick={handleEventbriteLogin}>
                  Login to Eventbrite
                </Button>
              )}

              <Box mt={2}>
                {eventbriteEvents.length > 0 ? (
                  eventbriteEvents.map((event) => (
                    <Paper elevation={3} style={{ padding: '10px', marginBottom: '10px' }} key={event.id}>
                      <Typography variant="body1">{event.name.text}</Typography>
                      <Typography variant="body2">{new Date(event.start.utc).toLocaleString()}</Typography>
                      {unsyncedEventbrite.some((unsyncedEvent) => unsyncedEvent.name.text === event.name.text) && (
                        <Box>
                          {loadingStateEventbrite[event.name.text] ? (
                            <CircularProgress size={24} style={{ marginTop: 10 }} />
                          ) : (
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => handleSyncEventbriteEvent(event)}
                              style={{ marginTop: 10 }}
                            >
                              Sync
                            </Button>
                          )}
                          {errorStateEventbrite[event.name.text] && (
                            <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                              {errorStateEventbrite[event.name.text]}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Paper>
                  ))
                ) : (
                  <Typography variant="body2">No events available</Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Calendly Events */}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Calendly Events</Typography>
              <Box mt={2}>
                {calendlyEvents.length > 0 ? (
                  calendlyEvents.map((event) => (
                    <Paper elevation={3} style={{ padding: '10px', marginBottom: '10px' }} key={event.uri}>
                      <Typography variant="body1">{event.name}</Typography>
                      <Typography variant="body2">{new Date(event.start_time).toLocaleString()}</Typography>
                      {unsyncedCalendly.some((unsyncedEvent) => unsyncedEvent.name === event.name) && (
                        <Box>
                          {loadingStateCalendly[event.name] ? (
                            <CircularProgress size={24} style={{ marginTop: 10 }} />
                          ) : (
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => handleSyncCalendlyEvent(event)}
                              style={{ marginTop: 10 }}
                            >
                              Sync
                            </Button>
                          )}
                          {errorStateCalendly[event.name] && (
                            <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                              {errorStateCalendly[event.name]}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Paper>
                  ))
                ) : (
                  <Typography variant="body2">No events available</Typography>
                )}
              </Box>
              {!calendlyEvents.length && (
                <Button variant="contained" color="primary" onClick={handleCalendlyLogin}>
                  Login to Calendly
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Button variant="outlined" color="secondary" onClick={handleClearSaved} style={{ marginTop: 20 }}>
        Clear Saved Credentials
      </Button>
    </Container>
  );
};

export default App;
