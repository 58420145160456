import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import axios from 'axios';

const CalendlyCallback = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
 //   const client = 'dhslyKJnMv78qnTgZfS9JacBcAby94WCfnjvGavpuEI';
//    const secret = '7buXpbtRBpWfSBdalQrH8bg4jSAJOfenafSd466vbug';

    const client = 'oCcK4x-I8CtrLMigzfdmKQX9JYLI1z9hl2qWAiwoL-o';
    const secret = 'VdPFSTS2Kz8C36_DszeGdoUbmgEQ0Ucfq6cOfKPWzNs';


    const exchangeCodeForToken = async (code) => {
      try {
        const response = await axios.post(
          'https://auth.calendly.com/oauth/token',
          new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: client,  // Replace with your client ID
            client_secret: secret,  // Replace with your client secret
            code: code,
            redirect_uri: `${window.location.origin}/calendly/callback`,  // Replace with your redirect URI
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        
        // Store the access token
        const accessToken = response.data.access_token;
        localStorage.setItem('calendly_access_token', accessToken);

        // Redirect to the /sync route
        navigate('/sync');
      } catch (error) {
        console.error('Error exchanging Calendly code for access token:', error);
      }
    };

    // Get the authorization code from the URL
    const hash = window.location.href.split('=');
    if (hash[0].indexOf('code') > 0) {
      const code = hash[hash.length - 1];

      if (code) {
        // Exchange the authorization code for an access token
        exchangeCodeForToken(code);
      }
    }
  }, [navigate]); // Add navigate to the dependency array

  return <div>Authenticating Calendly...</div>;
};

export default CalendlyCallback;
