import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const EventbriteCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Get the access token from the URL hash
    const hash = window.location.hash;
    const token = new URLSearchParams(hash.substring(1)).get('access_token');

    if (token) {
      // Store the access token (e.g., in localStorage or state)
      localStorage.setItem('eventbrite_access_token', token);

      // Redirect to your events page or wherever you want
      navigate("/sync");
    }
  },[navigate]);

  return <div>Authenticating...</div>;
};

export default EventbriteCallback;
